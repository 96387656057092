import type { UrlObject } from "url";
import type { MenuItemProps } from "../components/partials/widget/widget-menu/widget-menu-default";

export const pathMatch = (currentPath: string, menuItem: MenuItemProps): boolean => {
  if(menuItem.type === 'link' || menuItem.type === 'space' || menuItem.type === 'content'){
    return false
  }
  
  const path = menuItem.url ? new URL(menuItem.url).pathname : '';

  return path.replace(/\//g, '') === currentPath.replace(/\//g, '');
};

export const linkUrl = (link: string | UrlObject, isPlattform?: boolean): string => {
  let url;
  if (typeof link === 'string') {
    if (!link.startsWith("http")) {
      return link;
    }
    url = new URL(link);
  } else {
    url = link
  }
  
  if (url && (isPlattform || url.host === "www.rtl.de")) {
    return `${url.pathname ?? ''}${url.search ?? ''}${url.hash ?? ''}`;
  }

  return url ? url.toString() : '';
}

