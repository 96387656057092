import { clsx } from 'clsx';
import { type LinkProps } from 'next/link';
import type { PropsWithClassName } from '../../../types/react-props';
import { linkUrl } from '../../../utils/url';
import { Icon } from '../Icon';
import Image, { type ImageProps } from '../Image/Image';
import Link from '../Link/Link';
import { SponsoredIcon } from '../SponsoredIcon';
import { Typography } from '../Typography';
import styles from './Teaser.module.scss';

type TeaserGenericProps = PropsWithClassName<{
  readonly headline: string;
  readonly kicker?: string;
  readonly linkHref: LinkProps['href'];
  readonly image?: ImageProps;
  readonly openLinkInNewTab?: boolean;
  readonly hasIntroVideo?: boolean;
  readonly isSmallOnMobile?: boolean;
  readonly isSponsored?: boolean;
}>;

type TeaserVariantProps =
  | {
      readonly variant?: 'default';
      readonly buttonVariant?: 'default' | 'filled';
      readonly buttonText?: string;
    }
  | {
      readonly variant?: 'lead' | 'video';
      readonly buttonVariant?: never;
      readonly buttonText?: never;
    };

export type TeaserProps = TeaserGenericProps & TeaserVariantProps;

const Teaser = ({
  headline,
  kicker,
  linkHref,
  image,
  openLinkInNewTab = false,
  hasIntroVideo = false,
  variant = 'default',
  isSmallOnMobile = false,
  buttonVariant = 'default',
  buttonText,
  className,
  isSponsored = false,
}: TeaserProps) => (
  <div
    className={clsx(styles.teaser, className, variant === 'lead' ? styles.lead : styles.default, {
      [styles.smallMobile]: isSmallOnMobile,
    })}
    data-id={variant === 'lead' ? 'lead' : undefined}
  >
    <Link className={styles.link} href={linkUrl(linkHref)} target={openLinkInNewTab ? '_blank' : undefined}>
      <div
        className={clsx(
          styles.imageWrapper,
          variant === 'lead' ? styles.imageWrapperInLead : styles.imageWrapperDefault
        )}
      >
        {isSponsored && <SponsoredIcon className={styles.sponsored} />}
        <Image
          image={image?.image}
          className={styles.image}
          alt={image?.alt}
          width={image?.width}
          fallback={{ className: styles.fallbackImage }}
          src={image?.src}
        />
        {variant === 'video' && (
          <Typography className={styles.videoButton} as="div" variant="3xs">
            <Icon name="playOutline" size={18} /> Video starten
          </Typography>
        )}
      </div>
      <div className={styles.textWrapper}>
        <h3 className={styles.h3}>
          <div className={clsx({ [styles.kickerWrapper]: ['video', 'default'].includes(variant) })}>
            {!!kicker && (
              <Typography className={styles.kicker} as="div" variant="4xs">
                {kicker}
              </Typography>
            )}
          </div>
          <Typography className={styles.headline} as="div" variant={variant === 'lead' ? '2xl' : 'm'}>
            {headline}
          </Typography>
        </h3>
        {hasIntroVideo && !buttonText && variant !== 'video' && (
          <Typography className={styles.videoTag} as="div" variant="4xs">
            mit Video
          </Typography>
        )}
      </div>
    </Link>
    {!!buttonText && (
      <div className={clsx(styles.teaserButton, { [styles.filled]: buttonVariant === 'filled' })}>{buttonText}</div>
    )}
  </div>
);

export default Teaser;
